<template>
  <!-- INQUIRY MODAL SIDEBAR -->
  <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div>
  <div
    style="z-index: 998"
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pb-12
      pt-28
      px-8
      overflow-y-auto
      bg-white
      w-1/3
      shadow
      text-black
      flex flex-col
      shadow-2xl
      z-50
      h-full
      fixed
    "
  >
    <div class="text-left z-50">
      <h1 class="text-xl font-bold">New Inquiry</h1>

      <div class="leading-loose">
        <form class="mt-4 bg-white">
          <div>
            <select
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              v-model="newInquiry.branch"
            >
              <option disabled value="null">Select Branch</option>
              <option
                v-for="(branch, i) in branches"
                :key="i"
                :value="branch._id"
              >
                {{ branch.code }}
              </option>
            </select>
          </div>

          <div class="mt-2">
            <select
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              v-model="customerId"
            >
              <option disabled value="null">Select Customer</option>
              <option
                v-for="(customer, i) in customers"
                :key="i"
                :value="customer._id"
              >
                {{ customer.customerId }} {{ customer.contactName }} -
                {{
                  customer.customerCompany && customer.customerCompany.name
                    ? customer.customerCompany.name
                    : "NO COMPANY ADDED"
                }}
              </option>
            </select>
          </div>
          <div class="inline-block mt-2 w-1/2">
            <input
              disabled
              v-model="newInquiry.customerId"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="CustomerID"
              type="text"
              required=""
              placeholder="Customer ID"
            />
          </div>
          <div class="inline-block mt-2 pl-1 w-1/2">
            <input
              v-model="newInquiry.date"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="date"
              type="date"
              required=""
              placeholder="Date"
              aria-label="Email"
            />
          </div>
          <div class="mt-2">
            <input
              disabled
              v-model="newInquiry.customer"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Customer"
              type="text"
              required=""
              placeholder="Customer"
            />
          </div>
          <div class="mt-2">
            <input
              disabled
              v-model="newInquiry.contactName"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Name"
              type="text"
              required=""
              placeholder="Name"
            />
          </div>
          <div class="mt-2">
            <input
              disabled
              v-model="newInquiry.address"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Address"
              type="text"
              required=""
              placeholder="Address"
            />
          </div>

          <div class="inline-block pr-2 mt-2 w-1/2">
            <input
              disabled
              v-model="newInquiry.contactEmail"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Contact Email"
              type="text"
              required=""
              placeholder="Contact Email"
            />
          </div>
          <div class="inline-block mt-2 w-1/2">
            <input
              disabled
              v-model="newInquiry.contactMobile"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Contact Number"
              type="text"
              required=""
              placeholder="Contact Number"
            />
          </div>
          <div class="mt-2">
            <input
              v-model="newInquiry.assignee"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Assignee"
              type="text"
              required=""
              placeholder="Assignee"
            />
          </div>

          <div class="mt-2">
            <input
              v-model="newInquiry.description"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Description"
              type="text"
              required=""
              placeholder="Description"
            />
          </div>

          <div class="mt-2">
            <CategoriesBadge
              @click.prevent="selectOrUnselectCategory(category)"
              :active="
                newInquiry.categories.includes(category._id) ? true : false
              "
              v-for="category in categories"
              :key="category._id"
              :category="category"
            ></CategoriesBadge>
          </div>
          <div class="mt-4">
            <button
              @click.prevent="$emit('overlayClicked')"
              class="
                px-4
                mr-2
                py-2
                text-black
                font-light
                text-sm
                tracking-wider
                bg-gray-100
                hover:bg-gray-200
                rounded
              "
              type="submit"
            >
              Cancel
            </button>
            <button
              :disabled="!canSave"
              :class="{ 'cursor-not-allowed': !canSave }"
              @click.prevent="saveInquiry"
              class="
                px-4
                py-2
                text-sm text-white
                font-light
                tracking-wider
                bg-gray-900
                hover:bg-gray-700
                rounded
              "
              type="submit"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- INQUIRY MODAL SIDEBAR -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CategoriesBadge from "@/components/base/CategoriesBadge";

export default {
  name: "NewInquiryModal",
  props: ["isVisible"],
  emits: ["overlayClicked"],
  data() {
    return {
      newInquiry: {
        inquiryId: "",
        customerId: "",
        date: this.$moment().format("YYYY-MM-DD"),
        customer: "",
        contactName: "",
        address: "",
        contactEmail: "",
        contactMobile: "",
        assignee: "",
        description: "",
        branch: null,
        categories: [],
      },
      customerId: null,
    };
  },
  methods: {
    ...mapActions([
      "getCategories",
      "getBranches",
      "addInquiry",
      "getCustomers",
    ]),
    async saveInquiry() {
      await this.addInquiry({
        ...this.newInquiry,
        customerContactId: this.customer,
      });
      this.resetForm();
    },
    resetForm() {
      this.$emit("overlayClicked");
      this.customerId = null;
      this.newInquiry = {
        inquiryId: "",
        customerId: "",
        date: "",
        customer: "",
        contactName: "",
        address: "",
        contactEmail: "",
        contactMobile: "",
        assignee: "",
        description: "",
        branch: null,
        categories: [],
      };
      console.log(this.newInquiry);
    },
    selectOrUnselectCategory(category) {
      if (this.newInquiry.categories.includes(category._id)) {
        this.newInquiry.categories.splice(
          this.newInquiry.categories.indexOf(category._id),
          1
        );
      } else {
        this.newInquiry.categories.push(category._id);
      }
    },
  },
  watch: {
    customerId(id) {
      const index = this.customers.findIndex((c) => c._id == id);

      if (index >= 0) {
        this.newInquiry.contactEmail = this.customers[index]["contactEmail"];
        this.newInquiry.address = this.customers[index]["address"];
        this.newInquiry.customer = this.customers[index]["customer"];
        this.newInquiry.customerId = this.customers[index]["customerId"];
        this.newInquiry.contactName = this.customers[index]["contactName"];
        this.newInquiry.contactMobile = this.customers[index]["contactMobile"];

        if (
          this.customers[index]["customerCompany"] &&
          this.customers[index]["customerCompany"]["_id"]
        ) {
          this.newInquiry.customerCompany =
            this.customers[index]["customerCompany"]["_id"];
        }
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.getCustomers({ all: true });
  },
  computed: {
    ...mapGetters(["categories", "branches"]),
    ...mapGetters({ customers: "allCustomers" }),
    canSave() {
      return this.newInquiry.branch && this.newInquiry.date && this.customerId;
    },
  },
  components: {
    CategoriesBadge,
  },
};
</script>

<style scoped>
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}
</style>