<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->
  <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div>
  <div
    style="z-index: 998"
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pb-12
      pt-28
      px-8
      overflow-y-auto
      bg-white
      w-1/4
      text-black
      flex flex-col
      z-50
      h-full
      fixed
    "
  >
    <div class="text-left z-50">
      <h1 class="text-xl font-bold">
        Payments - {{ inquiry.inquiryIdString }}
      </h1>

      <form v-if="inquiry.dueAmount" class="mt-4 bg-white">
        <div class="inline-block grid gap-2 grid-cols-2 mt-2 w-full pr-1">
          <input
            v-model="newPayment.amount"
            class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
            name="actiontitle"
            type="number"
            required=""
            placeholder="Action Title"
          />
          <input
            v-model="newPayment.date"
            class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
            type="date"
            required=""
            placeholder="Date"
          />
        </div>
        <div class="inline-block mt-2 w-full pr-1">
          <input
            v-model="newPayment.remark"
            class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
            name="actiontitle"
            type="text"
            placeholder="Eg: converted from MVR to $ @15.42"
          />
        </div>

        <div class="mt-4">
          <button
            class="
              px-4
              mr-2
              py-2
              text-black
              font-light
              text-sm
              tracking-wider
              bg-gray-100
              hover:bg-gray-200
              rounded
            "
            @click.prevent="resetForm"
          >
            Clear
          </button>
          <button
            @click.prevent="
              addPayment({
                id: $props.inquiry._id,
                newPayment,
              }),
                resetForm()
            "
            :disabled="!newPayment.amount"
            :class="{ 'cursor-not-allowed': !newPayment.amount }"
            class="
              px-4
              py-2
              text-sm text-white
              font-light
              tracking-wider
              bg-gray-900
              hover:bg-gray-700
              rounded
            "
            type="submit"
          >
            Add
          </button>
        </div>
      </form>

      <div class="py-4" v-if="inquiry.dueAmount !== null">
        <span class="font-bold text-gray-400"
          >TOTAL: ${{ parseFloat(inquiry.grandTotal).toFixed(2) }}</span
        >&nbsp;&nbsp;&nbsp;<span class="font-bold text-yellow-600"
          >DUE: ${{ parseFloat(inquiry.dueAmount).toFixed(2) }}</span
        >
      </div>

      <div class="leading-loose">
        <div class="mt-8">
          <div class="flex justify-left">
            <div class="px-">
              <div
                v-for="payment in payments"
                :key="payment._id"
                class="flex flex-row w-full px-2"
              >
                <!--line column-->
                <div class="flex justify-left">
                  <div
                    style="background-color: #ccaf76"
                    class="
                      relative
                      flex
                      h-full
                      w-0.5
                      bg-black
                      items-center
                      justify-center
                    "
                  >
                    <div
                      style="background-color: #ccaf76"
                      class="
                        absolute
                        flex flex-col
                        p-1
                        justify-center
                        h-4
                        w-4
                        rounded-full
                        leading-none
                        text-center
                        z-10
                        bg-white
                        text-gray-400 text-sm
                      "
                    >
                      <div></div>
                    </div>
                  </div>
                </div>
                <!--right column  -->
                <div style="width: 400px" class="w-full px-8 py-1">
                  <div
                    class="
                      flex flex-col
                      w-full
                      rounded-lg
                      justify-left
                      bg-white
                      px-0
                      py-4
                    "
                  >
                    <div class="text-gray-600 flex relative">
                      <div class="">
                        <p class="text-md capitalize">${{ payment.amount }}</p>
                        <p class="text-md capitalize text-gray-400">
                          {{ payment.remark }}
                        </p>
                        <a
                          class="text-md"
                          style="color: #ccaf76"
                          target="_blank"
                        >
                          {{ $moment(payment.date).format("DD-MM-YYYY") }}
                        </a>
                        <p v-if="payment.addedBy" class="font-bold">
                          Added by: {{ payment.addedBy.name }}
                        </p>
                      </div>
                      <button
                        @click="
                          (paymentToDelete = json.parse(
                            json.stringify(payment)
                          )),
                            (deleteConfirmVisible = true)
                        "
                        class="absolute top-0 right-0"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          viewBox="0 0 24 24"
                          fill="white"
                          stroke="#ff0000"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- INQUIRY MODAL SIDEBAR -->

  <!-- DELETE CONFIRM MODAL -->
  <div
    v-if="deleteConfirmVisible"
    @click="deleteConfirmVisible = false"
    class="confirm-overlay"
  ></div>
  <div
    v-if="deleteConfirmVisible && paymentToDelete !== null"
    class="delete-confirm text-xl shadow rounded p-10"
  >
    <div>
      Delete this payment of
      <span class="font-bold">${{ paymentToDelete.amount.toFixed(2) }}?</span>
      <br />
      <span class="inline-block my-4 text-red-700"
        >This action cannot be undone.</span
      >
    </div>
    <div class="flex justify-end mt-6">
      <button
        @click="deleteConfirmVisible = false"
        class="py-1 px-4 bg-green-600 text-white rounded mr-2"
      >
        No
      </button>
      <button
        @click="
          (deleteConfirmVisible = false),
            deletePayment({
              inquiryId: inquiry._id,
              paymentId: paymentToDelete._id,
            })
        "
        class="py-1 px-4 bg-red-600 text-white rounded"
      >
        Yes
      </button>
    </div>
  </div>
  <!-- / DELETE CONFIRM MODAL -->
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      json: JSON,
      deleteConfirmVisible: false,
      newPayment: {
        amount: 0,
        date: this.$moment().format("YYYY-MM-DD"),
        remark: "",
      },
      paymentToDelete: null,
    };
  },
  methods: {
    ...mapActions(["addPayment", "deletePayment"]),
    resetForm() {
      this.newPayment = {
        amount: 0,
        date: this.$moment().format("YYYY-MM-DD"),
        remark: "",
      };
    },
  },
  name: "PaymentsModal",
  props: ["isVisible", "payments", "inquiry"],
  emits: ["overlayClicked"],
};
</script>

<style scoped>
.delete-confirm {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
}

.confirm-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9998;
}
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}
</style>