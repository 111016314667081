<template>
  <!-- INQUIRY MODAL SIDEBAR -->
  <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div>
  <div
    style="z-index: 998"
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pb-12
      pt-28
      px-8
      overflow-y-auto
      bg-white
      w-1/3
      text-black
      flex flex-col
      z-50
      h-full
      fixed
    "
  >
    <div class="text-left z-50">
      <h1 class="text-xl font-bold">Edit Inquiry</h1>

      <div class="leading-loose">
        <form class="mt-4 bg-white">
          <div>
            <select
              disabled
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              v-model="selectedInquiry.branch"
            >
              <option disabled value="null">Select Branch</option>
              <option
                v-for="(branch, i) in branches"
                :key="i"
                :value="branch._id"
              >
                {{ branch.code }}
              </option>
            </select>
          </div>

          <div class="inline-block mt-2 w-1/3 pr-1">
            <input
              disabled
              v-model="selectedInquiry.inquiryId"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="InquiryID"
              type="text"
              required=""
              placeholder="Inquiry ID"
              aria-label="Email"
            />
          </div>
          <div class="inline-block mt-2 pl-1 w-1/3">
            <input
              disabled
              v-model="selectedInquiry.customerId"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="CustomerID"
              type="text"
              required=""
              placeholder="Customer ID"
            />
          </div>
          <div class="inline-block mt-2 pl-1 w-1/3">
            <input
              v-model="selectedInquiry.date"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="date"
              type="date"
              required=""
              placeholder="Date"
              aria-label="Email"
            />
          </div>
          <div class="mt-2">
            <input
              disabled
              v-model="customerName"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Customer"
              type="text"
              required=""
              placeholder="Customer"
            />
          </div>
          <div class="mt-2">
            <input
              disabled
              v-model="selectedInquiry.contactName"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Name"
              type="text"
              required=""
              placeholder="Name"
            />
          </div>
          <div class="mt-2">
            <input
              disabled
              v-model="selectedInquiry.address"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Address"
              type="text"
              required=""
              placeholder="Address"
            />
          </div>

          <div class="inline-block pr-2 mt-2 w-1/2">
            <input
              disabled
              v-model="selectedInquiry.contactEmail"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Contact Email"
              type="text"
              required=""
              placeholder="Contact Email"
            />
          </div>
          <div class="inline-block mt-2 w-1/2">
            <input
              disabled
              v-model="selectedInquiry.contactMobile"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Contact Number"
              type="text"
              required=""
              placeholder="Contact Number"
            />
          </div>
          <div class="mt-2">
            <input
              v-model="selectedInquiry.assignee"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Assignee"
              type="text"
              required=""
              placeholder="Assignee"
            />
          </div>
          <div class="mt-2">
            <input
              v-model="selectedInquiry.description"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Description"
              type="text"
              required=""
              placeholder="Description"
            />
          </div>

          <h3
            v-if="selectedInquiry.customerStatus === 'approved'"
            class="font-bold text-sm mt-4"
          >
            Tracking Number
          </h3>
          <div
            v-if="selectedInquiry.customerStatus === 'approved'"
            class="mt-2 mb-4"
          >
            <input
              v-model="selectedInquiry.trackingNumber"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Description"
              type="text"
              required=""
              placeholder="Tracking Number"
            />
          </div>

          <h3
            v-if="selectedInquiry.customerStatus === 'approved'"
            class="font-bold text-sm mt-4"
          >
            Tracking Slug
          </h3>
          <div
            v-if="selectedInquiry.customerStatus === 'approved'"
            class="mt-2 mb-4"
          >
            <input
              v-model="selectedInquiry.trackingSlug"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Description"
              type="text"
              required=""
              placeholder="Tracking Number"
            />
          </div>

          <h3 class="font-bold text-sm mt-4">Date Ordered</h3>
          <div class="mt-2 mb-4">
            <input
              v-model="selectedInquiry.dateOrdered"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Description"
              type="date"
              required=""
              placeholder="Description"
            />
          </div>

          <h3
            v-if="selectedInquiry.customerStatus === 'approved'"
            class="font-bold text-sm mt-4"
          >
            Payment Due Date
          </h3>
          <div
            v-if="selectedInquiry.customerStatus === 'approved'"
            class="mt-2 mb-4"
          >
            <input
              v-model="selectedInquiry.paymentDueDate"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Description"
              type="date"
              required=""
              placeholder="Description"
            />
          </div>

          <h3
            v-if="selectedInquiry.customerStatus === 'approved'"
            class="font-bold text-sm mt-4"
          >
            ETA
          </h3>
          <div
            v-if="selectedInquiry.customerStatus === 'approved'"
            class="mt-2 mb-4"
          >
            <input
              v-model="selectedInquiry.eta"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="Description"
              type="date"
              required=""
              placeholder="Description"
            />
          </div>

          <div class="mt-2">
            <CategoriesBadge
              @click.prevent="selectOrUnselectCategory(category)"
              :active="
                selectedInquiry.categories.includes(category._id) ? true : false
              "
              v-for="category in categories"
              :key="category._id"
              :category="category"
            ></CategoriesBadge>
          </div>
          <div class="mt-4">
            <button
              @click.prevent="$emit('overlayClicked')"
              class="
                px-4
                mr-2
                py-2
                text-black
                font-light
                text-sm
                tracking-wider
                bg-gray-100
                hover:bg-gray-200
                rounded
              "
              type="submit"
            >
              Cancel
            </button>
            <button
              @click.prevent="saveInquiry"
              class="
                px-4
                py-2
                text-sm text-white
                font-light
                tracking-wider
                bg-gray-900
                hover:bg-gray-700
                rounded
              "
              type="submit"
            >
              SAVE
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- INQUIRY MODAL SIDEBAR -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CategoriesBadge from "@/components/base/CategoriesBadge";

export default {
  name: "EditInquiryModal",
  props: ["isVisible", "inquiry"],
  emits: ["overlayClicked"],
  data() {
    return {
      selectedInquiry: {
        inquiryId: "",
        paymentDueDate: "",
        customerId: "",
        date: "",
        customer: "",
        contactName: "",
        address: "",
        contactEmail: "",
        contactMobile: "",
        assignee: "",
        description: "",
        branch: null,
        categories: [],
      },
    };
  },
  methods: {
    ...mapActions(["editInquiry", "editInquiry"]),
    async saveInquiry() {
      await this.editInquiry({
        id: this.selectedInquiry._id,
        inquiry: this.selectedInquiry,
      });
      this.$emit("overlayClicked");
    },
    // resetForm() {
    //   this.$emit("overlayClicked");
    //   this.selectedInquiry = {
    //     inquiryId: "",
    //     customerId: "",
    //     date: "",
    //     customer: "",
    //     contactName: "",
    //     address: "",
    //     contactEmail: "",
    //     contactMobile: "",
    //     assignee: "",
    //     description: "",
    //     branch: null,
    //     categories: [],
    //   };
    // },
    selectOrUnselectCategory(category) {
      if (this.selectedInquiry.categories.includes(category._id)) {
        this.selectedInquiry.categories.splice(
          this.selectedInquiry.categories.indexOf(category._id),
          1
        );
      } else {
        this.selectedInquiry.categories.push(category._id);
      }
    },
  },
  computed: {
    ...mapGetters(["categories", "branches"]),
    customerName() {
      if (this.selectedInquiry && this.selectedInquiry.customer) {
        return this.selectedInquiry.customer;
      } else if (this.selectedInquiry && this.selectedInquiry.customerCompany) {
        return this.selectedInquiry.customerCompany.name;
      }
      return "-";
    },
  },
  mounted() {
    this.selectedInquiry = { ...this.$props.inquiry };
    this.selectedInquiry.branch = this.selectedInquiry.branch._id;
    this.selectedInquiry.categories = this.selectedInquiry.categories.map(
      (cat) => cat._id
    );
    this.selectedInquiry.date = this.$moment(this.selectedInquiry.date).format(
      "YYYY-MM-DD"
    );
    // Date Ordered
    if (!this.selectedInquiry.dateOrdered)
      this.selectedInquiry.dateOrdered = "";
    else
      this.selectedInquiry.dateOrdered = this.$moment(
        this.selectedInquiry.dateOrdered
      ).format("YYYY-MM-DD");

    // Payment Due Date
    if (!this.selectedInquiry.paymentDueDate)
      this.selectedInquiry.paymentDueDate = "";
    else
      this.selectedInquiry.paymentDueDate = this.$moment(
        this.selectedInquiry.paymentDueDate
      ).format("YYYY-MM-DD");

    // ETA Date
    if (!this.selectedInquiry.eta) this.selectedInquiry.eta = "";
    else
      this.selectedInquiry.eta = this.$moment(this.selectedInquiry.eta).format(
        "YYYY-MM-DD"
      );
  },
  components: {
    CategoriesBadge,
  },
};
</script>

<style scoped>
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}
</style>