<template>
  <tr
    :class="{
      'bg-red-50': inquiry.customerStatus == 'rejected',
      'bg-green-50': inquiry.customerStatus == 'approved',
      'bg-yellow-50': inquiry.customerStatus == 'onHold',
      'last-viewed': inquiry._id == lastViewedInquiry,
    }"
    class="bg-white hover:bg-gray-100 cursor-pointer"
  >
    <td class="px-1 py-1 text-left pl-2">
      <select
        @change="handleInquiryPriorityChange($event)"
        class="form-checkbox mt-1 h-4 w-4 text-gray-600 text rounded"
        :class="{
          'bg-red-300 text-red-300': inquiry.inquiryPriority == 'H',
          'bg-yellow-300 text-yellow-300': inquiry.inquiryPriority == 'M',
          'bg-blue-300 text-blue-300': inquiry.inquiryPriority == 'L',
        }"
      >
        <option :selected="inquiry.inquiryPriority === 'none'" value="none">
          N/A
        </option>
        <option :selected="inquiry.inquiryPriority === 'H'" value="H">H</option>
        <option :selected="inquiry.inquiryPriority === 'M'" value="M">M</option>
        <option :selected="inquiry.inquiryPriority === 'L'" value="L">L</option>
      </select>
    </td>
    <td
      @click="$router.push('/inquiries/' + inquiry._id)"
      class="px-1 py-2 text-left"
    >
      {{ inquiry.branch.code }}{{ inquiry.inquiryId }}
    </td>
    <td
      @click="$router.push('/inquiries/' + inquiry._id)"
      class="px-1 py-2 text-left w-14"
    >
      {{ inquiry.customerId }}
    </td>
    <td
      @click="$router.push('/inquiries/' + inquiry._id)"
      class="px-1 py-2 text-left"
    >
      {{ customerName }}
    </td>
    <td
      @click="$router.push('/inquiries/' + inquiry._id)"
      class="px-1 py-2 text-left"
    >
      {{ inquiry.contactName }}
    </td>
    <td
      @click="$router.push('/inquiries/' + inquiry._id)"
      class="px-1 py-2 text-left"
    >
      {{ inquiry.description }}
    </td>
    <td
      @click="$router.push('/inquiries/' + inquiry._id)"
      class="px-1 py-2 text-left"
    >
      <span v-if="inquiry.grandTotal && inquiry.grandTotal !== 'NO USD RATE'">{{
        inquiry.grandTotal ? `$${inquiry.grandTotal.toFixed(2)}` : "-"
      }}</span>
      <span
        class="font-bold text-red-500"
        v-if="inquiry.grandTotal === 'NO USD RATE'"
        >NO USD RATE</span
      >
      <span v-if="!inquiry.grandTotal">-</span>
    </td>
    <td
      @click="$router.push('/inquiries/' + inquiry._id)"
      class="px-1 py-2 text-left"
    >
      <span
        v-if="inquiry.totalProfit && inquiry.totalProfit !== 'NO USD RATE'"
        >{{
          inquiry.totalProfit ? `$${inquiry.totalProfit.toFixed(2)}` : "-"
        }}</span
      >
      <span
        class="font-bold text-red-500"
        v-if="inquiry.totalProfit === 'NO USD RATE'"
        >NO USD RATE</span
      >
      <span v-if="!inquiry.totalProfit">-</span>
    </td>
    <td
      @click="$router.push('/inquiries/' + inquiry._id)"
      class="px-1 py-2 text-left"
    >
      <CategoriesBadge
        v-for="category in inquiry.categories"
        :key="category._id"
        :category="category"
      ></CategoriesBadge>
    </td>
    <td
      @click="$router.push('/inquiries/' + inquiry._id)"
      class="px-1 py-2 text-left w-20"
    >
      {{ $moment(inquiry.date).format("DD-MM-YYYY") }}
    </td>
    <!-- <td
      @click="$router.push('/inquiries/' + inquiry._id)"
      class="px-1 py-2 text-left"
    >
      {{ inquiry.assignee }}
    </td> -->
    <td class="px-1 py-2 text-left">
      <select
        @change="handleCustomerStatusChange($event)"
        style="background: inherit"
        class="justify-center px-1 rounded"
        name="cusstatus"
      >
        <option
          :selected="inquiry.customerStatus === 'notAvailable'"
          value="notAvailable"
        >
          N/A
        </option>
        <option :selected="inquiry.customerStatus === 'onHold'" value="onHold">
          ON HOLD
        </option>
        <option
          :selected="inquiry.customerStatus === 'rejected'"
          value="rejected"
        >
          REJECTED
        </option>
        <option
          :selected="inquiry.customerStatus === 'approved'"
          value="approved"
        >
          APPROVED
        </option>
      </select>
    </td>
    <td class="px-1 py-2 text-left">
      <select
        @change="handleInquiryStatusChange($event)"
        style="background: inherit"
        class="justify-center px-1 rounded"
        name="inwstatus"
      >
        <option
          :selected="inquiry.inquiryStatus === 'notAvailable'"
          value="notAvailable"
        >
          N/A
        </option>
        <option :selected="inquiry.inquiryStatus === 'pricing'" value="pricing">
          PRICING
        </option>
        <option :selected="inquiry.inquiryStatus === 'sent'" value="sent">
          SENT
        </option>
        <option :selected="inquiry.inquiryStatus === 'shipped'" value="shipped">
          SHIPPED
        </option>
        <option :selected="inquiry.inquiryStatus === 'onHand'" value="onHand">
          OH HAND
        </option>
        <option
          :selected="inquiry.inquiryStatus === 'delivered'"
          value="delivered"
        >
          DELIVERED
        </option>
        <option :selected="inquiry.inquiryStatus === 'pending'" value="pending">
          PENDING
        </option>
        <option :selected="inquiry.inquiryStatus === 'closed'" value="closed">
          CLOSED
        </option>
      </select>
    </td>
    <td class="px-1 py-2 flex items-center">
      <ActionHistoryModal
        :statusHistory="inquiry.statusHistory"
        @overlayClicked="ActionHistoryOverlayClicked"
        :isVisible="ActionHistoryVisible"
      ></ActionHistoryModal>
      <TrackingDataModal
        :trackingData="inquiry.trackingData"
        :inquiry="inquiry"
        @overlayClicked="trackingDataModalVisible = false"
        :isVisible="trackingDataModalVisible"
      ></TrackingDataModal>
      <PaymentsModal
        :inquiry="inquiry"
        :payments="inquiry.payments"
        @overlayClicked="paymentsModalVisible = false"
        :isVisible="paymentsModalVisible"
      ></PaymentsModal>

      <button
        :disabled="inquiry.statusHistory.length === 0"
        :class="{
          'cursor-not-allowed opacity-40': inquiry.statusHistory.length === 0,
        }"
        @click="ActionHistoryVisible = !ActionHistoryVisible"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 mr-2"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
          <circle cx="12" cy="12" r="3"></circle>
        </svg>
      </button>
      <button
        :disabled="inquiry.trackingData.length === 0"
        :class="{
          'cursor-not-allowed opacity-40': inquiry.trackingData.length === 0,
        }"
        @click="trackingDataModalVisible = !trackingDataModalVisible"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M12 22s-8-4.5-8-11.8A8 8 0 0 1 12 2a8 8 0 0 1 8 8.2c0 7.3-8 11.8-8 11.8z"
          />
          <circle cx="12" cy="10" r="3" />
        </svg>
      </button>

      <!-- PAYMENTS BUTTON -->
      <button
        :class="{
          'cursor-not-allowed opacity-40':
            inquiry.customerStatus !== 'approved',
        }"
        :disabled="inquiry.customerStatus !== 'approved'"
        @click="paymentsModalVisible = !paymentsModalVisible"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      </button>

      <NewActionHistoryModal
        :inquiryId="inquiry._id"
        @overlayClicked="newActionHistoryOverlayClicked"
        :isVisible="newActionHistoryVisible"
      ></NewActionHistoryModal>

      <NewTrackingDataModal
        :inquiry="inquiry"
        @overlayClicked="newTrackingDataModalVisible = false"
        :isVisible="newTrackingDataModalVisible"
      ></NewTrackingDataModal>

      <EditInquiryModal
        :inquiry="inquiry"
        @overlayClicked="editInquiryVisible = false"
        :isVisible="editInquiryVisible"
      ></EditInquiryModal>

      <FileUploadModal
        :customerFiles="true"
        :locked="false"
        :selectedFiles="files"
        :isVisible="fileUploadModalVisible"
        :documents="inquiry.customerDocs"
        @selected="handleDocumentSelect"
        @overlayClicked="fileUploadModalVisible = false"
      ></FileUploadModal>

      <button @click="newActionHistoryVisible = !newActionHistoryVisible">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 mr-2"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      </button>

      <div
        @click="showDropDown = false"
        v-if="showDropDown"
        class="dropDownOverlay"
      ></div>

      <button @click="showDropDown = !showDropDown" class="relative">
        <svg
          class="h-5 w-5 fill-current text-grey-dark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M4 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
          />
        </svg>

        <div
          v-if="showDropDown"
          style="width: 150px"
          class="dropdown text-left absolute top-0 right-0 z-50"
        >
          <ul class="w-full bg-white shadow rounded text-xl">
            <li
              class="py-2 px-4 hover:bg-gray-100 text-sm"
              @click="fileUploadModalVisible = true"
            >
              Customer Files
            </li>
            <li
              v-if="inquiry.customerStatus === 'approved'"
              class="py-2 px-4 hover:bg-gray-100 text-sm"
              @click="newTrackingDataModalVisible = true"
            >
              Add Tracking Update
            </li>
            <li
              class="py-2 px-4 hover:bg-gray-100 text-sm"
              @click="editInquiryVisible = true"
            >
              Edit
            </li>
            <li
              @click="deleteConfirmVisible = true"
              class="py-2 px-4 hover:bg-gray-100 text-sm"
            >
              Delete
            </li>
          </ul>
        </div>
      </button>

      <!-- DELETE CONFIRM MODAL -->
      <div
        v-if="deleteConfirmVisible"
        @click="deleteConfirmVisible = false"
        class="confirm-overlay"
      ></div>
      <div
        v-if="deleteConfirmVisible"
        class="delete-confirm text-xl shadow rounded p-10"
      >
        <div>
          Delete Inquiry
          <span class="font-bold"
            >{{ inquiry.branch.code }}{{ inquiry.inquiryId }}?</span
          >
          <br />
          <span class="inline-block my-4 text-red-700"
            >This action cannot be undone.</span
          >
        </div>
        <div class="flex justify-end mt-6">
          <button
            @click="deleteConfirmVisible = false"
            class="py-1 px-4 bg-green-600 text-white rounded mr-2"
          >
            No
          </button>
          <button
            @click="(deleteConfirmVisible = false), deleteInquiry(inquiry._id)"
            class="py-1 px-4 bg-red-600 text-white rounded"
          >
            Yes
          </button>
        </div>
      </div>
      <!-- / DELETE CONFIRM MODAL -->
    </td>
  </tr>
</template>

<script>
import NewActionHistoryModal from "@/components/modules/inquiries/NewActionHistoryModal";
import NewTrackingDataModal from "@/components/modules/inquiries/NewTrackingDataModal";
import ActionHistoryModal from "@/components/modules/inquiries/ActionHistoryModal";
import CategoriesBadge from "@/components/base/CategoriesBadge";
import EditInquiryModal from "@/components/modules/inquiries/EditInquiryModal";
import PaymentsModal from "@/components/modules/inquiries/PaymentsModal";
import FileUploadModal from "@/components/base/FileUploadModal";
import TrackingDataModal from "@/components/modules/inquiries/TrackingDataModal";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InquiryTableItem",
  props: ["inquiry"],
  data() {
    return {
      trackingDataModalVisible: false,
      newTrackingDataModalVisible: false,
      newActionHistoryVisible: false,
      fileUploadModalVisible: false,
      paymentsModalVisible: false,
      ActionHistoryVisible: false,
      editInquiryVisible: false,
      deleteConfirmVisible: false,
      showDropDown: false,
      files: [],
      deletedFiles: [],
    };
  },
  methods: {
    ...mapActions([
      "setInquiryStatus",
      "setInquiryPriority",
      "setCustomerStatus",
      "deleteInquiry",
      "editInquiry",
    ]),
    handleDocumentSelect(data) {
      this.files = data.files;

      if (data.deletedFiles && data.deletedFiles.length) {
        data.deletedFiles.forEach((file) => {
          this.deletedFiles.push(file.file);
        });
      }

      this.editInquiry({
        id: this.inquiry._id,
        inquiry: this.inquiry,
        files: this.files,
        deletedFiles: this.deletedFiles,
      });
    },
    handleInquiryStatusChange(e) {
      this.setInquiryStatus({
        id: this.$props.inquiry._id,
        inquiryStatus: e.target.value,
      });
    },
    handleInquiryPriorityChange(e) {
      this.setInquiryPriority({
        id: this.$props.inquiry._id,
        inquiryPriority: e.target.value,
      });
    },
    handleCustomerStatusChange(e) {
      this.setCustomerStatus({
        id: this.$props.inquiry._id,
        customerStatus: e.target.value,
      });
    },
    newActionHistoryOverlayClicked() {
      this.newActionHistoryVisible = false;
    },
    ActionHistoryOverlayClicked() {
      this.ActionHistoryVisible = false;
    },
  },
  computed: {
    ...mapGetters(["lastViewedInquiry"]),
    customerName() {
      if (this.inquiry && this.inquiry.customer) {
        return this.inquiry.customer;
      } else if (this.inquiry && this.inquiry.customerCompany) {
        return this.inquiry.customerCompany.name;
      }
      return "-";
    },
  },
  components: {
    NewActionHistoryModal,
    NewTrackingDataModal,
    ActionHistoryModal,
    CategoriesBadge,
    EditInquiryModal,
    PaymentsModal,
    FileUploadModal,
    TrackingDataModal,
  },
};
</script>

<style scoped>
.dropDownOverlay {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 49;
}

.delete-confirm {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
}

.confirm-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9998;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.last-viewed {
  box-shadow: inset 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: inset 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: inset 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: inset 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: inset 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -moz-box-shadow: inset 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: inset 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: inset 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
</style>