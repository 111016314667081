<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->
  <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div>
  <div
    style="z-index: 998"
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pb-12
      pt-28
      px-8
      overflow-y-auto
      bg-white
      w-1/5
      text-black
      flex flex-col
      z-50
      h-full
      fixed
    "
  >
    <div class="text-left z-50">
      <div class="flex justify-between">
        <h1 class="text-xl font-bold">Filter</h1>

        <button
          @click="clearFilters"
          class="disabled:opacity-50 bg-gray-200 text-sm rounded px-2 py-1"
        >
          Clear filters
        </button>
      </div>
      <div class="leading-loose mt-10">
        <h3 class="text-black text-sm">Customer Status</h3>
        <button
          @click="
            selectOrUnselectFilter({
              type: 'customerStatus',
              value: 'notAvailable',
            })
          "
          :class="{
            'bg-primary':
              filters.findIndex(
                (f) => f.type === 'customerStatus' && f.value === 'notAvailable'
              ) !== -1,
            'bg-gray-500 hover:bg-gray-400':
              filters.findIndex(
                (f) => f.type === 'customerStatus' && f.value === 'notAvailable'
              ) === -1,
          }"
          class="uppercase text-white text-xs mr-1 rounded px-1 py-1"
        >
          n/a
        </button>
        <button
          @click="
            selectOrUnselectFilter({
              type: 'customerStatus',
              value: 'onHold',
            })
          "
          :class="{
            'bg-primary':
              filters.findIndex(
                (f) => f.type === 'customerStatus' && f.value === 'onHold'
              ) !== -1,
            'bg-gray-500 hover:bg-gray-400':
              filters.findIndex(
                (f) => f.type === 'customerStatus' && f.value === 'onHold'
              ) === -1,
          }"
          class="uppercase text-white text-xs mr-1 rounded px-1 py-1"
        >
          on hold
        </button>
        <button
          @click="
            selectOrUnselectFilter({
              type: 'customerStatus',
              value: 'rejected',
            })
          "
          :class="{
            'bg-primary':
              filters.findIndex(
                (f) => f.type === 'customerStatus' && f.value === 'rejected'
              ) !== -1,
            'bg-gray-500 hover:bg-gray-400':
              filters.findIndex(
                (f) => f.type === 'customerStatus' && f.value === 'rejected'
              ) === -1,
          }"
          class="uppercase text-white text-xs mr-1 rounded px-1 py-1"
        >
          rejected
        </button>
        <button
          @click="
            selectOrUnselectFilter({
              type: 'customerStatus',
              value: 'approved',
            })
          "
          :class="{
            'bg-primary':
              filters.findIndex(
                (f) => f.type === 'customerStatus' && f.value === 'approved'
              ) !== -1,
            'bg-gray-500 hover:bg-gray-400':
              filters.findIndex(
                (f) => f.type === 'customerStatus' && f.value === 'approved'
              ) === -1,
          }"
          class="uppercase text-white text-xs mr-1 rounded px-1 py-1"
        >
          approved
        </button>
        <button
          @click="
            selectOrUnselectFilter({
              type: 'customerStatus',
              value: 'accepted',
            })
          "
          :class="{
            'bg-primary':
              filters.findIndex(
                (f) => f.type === 'customerStatus' && f.value === 'accepted'
              ) !== -1,
            'bg-gray-500 hover:bg-gray-400':
              filters.findIndex(
                (f) => f.type === 'customerStatus' && f.value === 'accepted'
              ) === -1,
          }"
          class="uppercase text-white text-xs mr-1 rounded px-1 py-1"
        >
          accepted
        </button>
      </div>

      <div class="leading-loose mt-4">
        <h3 class="text-black text-sm">Inquiry Status</h3>
        <button
          @click="
            selectOrUnselectFilter({
              type: 'inquiryStatus',
              value: 'notAvailable',
            })
          "
          :class="{
            'bg-primary':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'notAvailable'
              ) !== -1,
            'bg-gray-500 hover:bg-gray-400':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'notAvailable'
              ) === -1,
          }"
          class="uppercase text-white text-xs mr-1 rounded px-1 py-1"
        >
          n/a
        </button>
        <button
          @click="
            selectOrUnselectFilter({
              type: 'inquiryStatus',
              value: 'pricing',
            })
          "
          :class="{
            'bg-primary':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'pricing'
              ) !== -1,
            'bg-gray-500 hover:bg-gray-400':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'pricing'
              ) === -1,
          }"
          class="uppercase text-white text-xs mr-1 rounded px-1 py-1"
        >
          pricing
        </button>
        <button
          @click="
            selectOrUnselectFilter({
              type: 'inquiryStatus',
              value: 'sent',
            })
          "
          :class="{
            'bg-primary':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'sent'
              ) !== -1,
            'bg-gray-500 hover:bg-gray-400':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'sent'
              ) === -1,
          }"
          class="uppercase text-white text-xs mr-1 rounded px-1 py-1"
        >
          sent
        </button>
        <button
          @click="
            selectOrUnselectFilter({
              type: 'inquiryStatus',
              value: 'shipped',
            })
          "
          :class="{
            'bg-primary':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'shipped'
              ) !== -1,
            'bg-gray-500 hover:bg-gray-400':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'shipped'
              ) === -1,
          }"
          class="uppercase text-white text-xs mr-1 rounded px-1 py-1"
        >
          shipped
        </button>
        <button
          @click="
            selectOrUnselectFilter({
              type: 'inquiryStatus',
              value: 'onHand',
            })
          "
          :class="{
            'bg-primary':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'onHand'
              ) !== -1,
            'bg-gray-500 hover:bg-gray-400':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'onHand'
              ) === -1,
          }"
          class="uppercase text-white text-xs mr-1 rounded px-1 py-1"
        >
          on hand
        </button>
        <button
          @click="
            selectOrUnselectFilter({
              type: 'inquiryStatus',
              value: 'delivered',
            })
          "
          :class="{
            'bg-primary':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'delivered'
              ) !== -1,
            'bg-gray-500 hover:bg-gray-400':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'delivered'
              ) === -1,
          }"
          class="uppercase text-white text-xs mr-1 rounded px-1 py-1"
        >
          delivered
        </button>
        <button
          @click="
            selectOrUnselectFilter({
              type: 'inquiryStatus',
              value: 'pending',
            })
          "
          :class="{
            'bg-primary':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'pending'
              ) !== -1,
            'bg-gray-500 hover:bg-gray-400':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'pending'
              ) === -1,
          }"
          class="uppercase text-white text-xs mr-1 rounded px-1 py-1"
        >
          pending
        </button>
        <button
          @click="
            selectOrUnselectFilter({
              type: 'inquiryStatus',
              value: 'closed',
            })
          "
          :class="{
            'bg-primary':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'closed'
              ) !== -1,
            'bg-gray-500 hover:bg-gray-400':
              filters.findIndex(
                (f) => f.type === 'inquiryStatus' && f.value === 'closed'
              ) === -1,
          }"
          class="uppercase text-white text-xs mr-1 rounded px-1 py-1"
        >
          closed
        </button>
      </div>

      <div class="leading-loose mt-4">
        <h3 class="text-black text-sm">Categories</h3>
        <button
          v-for="category in categories"
          :key="category._id"
          @click="
            selectOrUnselectFilter({
              type: 'categories',
              value: category._id,
            })
          "
          :class="{
            'bg-primary':
              filters.findIndex(
                (f) => f.type === 'categories' && f.value === category._id
              ) !== -1,
            'bg-gray-500 hover:bg-gray-400':
              filters.findIndex(
                (f) => f.type === 'categories' && f.value === category._id
              ) === -1,
          }"
          class="text-white text-xs mr-1 rounded px-1 py-1"
        >
          {{ category.name }}
        </button>
      </div>
      <div class="leading-loose mt-4">
        <button
          @click="cancelFiltering"
          class="
            border
            hover:bg-gray-100
            text-black text-xs
            mr-1
            rounded
            px-1.5
            py-1.5
          "
        >
          CANCEL
        </button>
        <button
          style="background-color: #cfb47e"
          class="
            hover:bg-gray-400
            text-black text-xs
            mr-1
            rounded
            px-1.5
            py-1.5
          "
          @click="setFilters"
        >
          DONE
        </button>
      </div>
    </div>
  </div>
  <!-- INQUIRY MODAL SIDEBAR -->
</template>

<script>
import { mapGetters } from "vuex";
import mutations from "@/utils/mutation-types";
export default {
  name: "FilterModal",
  props: ["isVisible"],
  emits: ["overlayClicked", "filtered"],
  data() {
    return {
      filters: [
        /*{ type: "customerStatus", value: "onHold" }*/
      ],
    };
  },
  mounted() {
    this.filters = [...this.stateFilters];
  },
  methods: {
    cancelFiltering() {
      this.filters = [...this.stateFilters];
      this.$emit("overlayClicked");
    },
    clearFilters() {
      this.$store.commit(mutations.SET_FILTERS, []);
      this.filters = [];
      this.$emit("filtered");
      this.$emit("overlayClicked");
    },
    setFilters() {
      this.$store.commit(mutations.SET_FILTERS, this.filters);
      this.$emit("filtered");
      this.$emit("overlayClicked");
    },
    selectOrUnselectFilter(filter) {
      const index = this.filters.findIndex(
        (f) => f.type === filter.type && f.value === filter.value
      );
      // console.log(index);
      if (index === -1) {
        this.filters.push(filter);
      } else {
        this.filters = this.filters.filter((f, i) => i != index);
      }
    },
  },
  computed: {
    ...mapGetters({ categories: "categories", stateFilters: "filters" }),
  },
};
</script>

<style scoped>
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}
</style>