<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->
  <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div>
  <div
    style="z-index: 998"
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pb-12
      pt-28
      px-8
      overflow-y-auto
      bg-white
      w-1/4
      text-black
      flex flex-col
      z-50
      h-full
      fixed
    "
  >
    <div class="text-left z-50">
      <h1 class="text-xl font-bold">
        Add Tracking Data - {{ inquiry.inquiryIdString }}
      </h1>

      <div class="leading-loose">
        <form @submit.prevent class="mt-4 bg-white">
          <div class="inline-block mt-2 w-full pr-1">
            <input
              v-model="newTrackingData.location"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              required=""
              placeholder="Location"
            />
          </div>
          <div class="inline-block mt-2 w-full pr-1">
            <input
              v-model="newTrackingData.description"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              required=""
              placeholder="Description (Optional)"
            />
          </div>
          <div class="inline-block mt-2 w-1/2">
            <input
              v-model="newTrackingData.time"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              type="datetime-local"
              required=""
              placeholder="Date"
            />
          </div>

          <div class="mt-4">
            <button
              class="
                px-4
                mr-2
                py-2
                text-black
                font-light
                text-sm
                tracking-wider
                bg-gray-100
                hover:bg-gray-200
                rounded
              "
              @click.prevent="resetForm"
            >
              Cancel
            </button>
            <button
              :disabled="!canSave"
              :class="{ 'cursor-not-allowed': !canSave }"
              @click.prevent="
                addTrackingData({
                  id: $props.inquiry._id,
                  newItem: newTrackingData,
                }),
                  resetForm()
              "
              class="
                px-4
                py-2
                text-sm text-white
                font-light
                tracking-wider
                bg-gray-900
                hover:bg-gray-700
                rounded
              "
              type="submit"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- INQUIRY MODAL SIDEBAR  -->
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NewActionHistoryModal",
  props: ["isVisible", "inquiry"],
  emits: ["overlayClicked"],
  data() {
    return {
      newTrackingData: {
        location: "",
        description: "",
        time: this.$moment().format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    ...mapActions(["addTrackingData"]),
    resetForm() {
      this.newTrackingData = {
        location: "",
        description: "",
        time: "",
      };
      this.$emit("overlayClicked");
    },
  },
  computed: {
    canSave() {
      const { location, time } = this.newTrackingData;
      return time && location;
    },
  },
};
</script>

<style scoped>
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}
</style>